<template>
  <div>
    <invoice :data="invoiceData"></invoice>

    <div class="d-print-none" style="text-align: center; margin-bottom: 32px;margin-top: 16px;">
      <v-btn @click="print" rounded outlined color="primary" style="height: 32px;width: 86px">
        <img :src="printIcon" alt="print icon">
        <span class="fs-14 fw-400" style="margin-left: 2px;">Print</span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import printIcon from '@/assets/image/printer.svg'
import invoice from 'cpp-js-util/components/common/Invoice.vue'
import QRCode from 'qrcode'

export default {
  name: "Invoice",
  props: ["orderId"],
  components: {
    invoice: invoice,
  },

  created() {
    this.getInvoiceData();
  },

  data() {
    return {
      payload: {
        "orderId": this.orderId
      },
      merchantInfo: {},
      orderInfo: {},
      invoiceData: {},
      printIcon: printIcon,
    }
  },

  methods: {
    async getInvoiceData(){
      this.merchantInfo = await this.fetchMerchantInfo();
      this.orderInfo = await this.fetchOrderDetailsData();
      this.invoiceData = { ...this.merchantInfo, ...this.orderInfo};
    },
    async generateQrCode(data) {
      try {
        let orderQrCode = `BBORD|${data.orderId}|END`;
        let codeUrl = await QRCode.toDataURL(orderQrCode, {errorCorrectionLevel: 'H'});
        return codeUrl;
      } catch (ex) {
        console.error(ex);
      }
    },
    async fetchMerchantInfo(){
      this.$feedback.showLoading();
      try {
        let accountInfo = await this.$cppClient.post('profile/info', {});
        accountInfo = accountInfo.data;
        let merchantInfo = {}
        merchantInfo.merchantName = accountInfo.name;
        merchantInfo.contactEmail = accountInfo.contactEmail;
        merchantInfo.contactPhone = accountInfo.contactNumber;
        merchantInfo.merchantLogo = this.$store.getters.getPGWLogo;
        this.$feedback.hideLoading();
        return merchantInfo;
      } catch (err) {
        this.$feedback.hideLoading();
        await this.$feedback.showFailed(err.response);
        await this.$router.push({ name: "dashboard" });
      }
    },
    async fetchOrderDetailsData() {
      this.$feedback.showLoading();
      try {
        let data = await this.$cppClient.post('/order/details', this.payload);
        data = data.data;
        data.qrImg = await this.generateQrCode(data);
        this.$feedback.hideLoading();
        return data;
      } catch (err) {
        this.$feedback.hideLoading();
        await this.$feedback.showFailed(err.response);
        await this.$router.push({ name: "dashboard" });
      }
    },
    print() {
      window.print();
    }
  }
}
</script>

<style scoped>

</style>