<template>
  <div class="container invoice-container">
    <div style="display: flex;justify-content: space-between;align-items: start">
      <div>
        <div class="row margin-style" style="margin-bottom: 5px; margin-left: 6px">
          <img class="merchant-logo" :src="data.merchantLogo" alt="merchant-logo">
        </div>
        <div class="merchant-info">
          <div class="merchant-name word-break">
            {{ data.merchantName }}
          </div>
          <ul class="info-container">
            <li v-if="data.contactPhone" class="payment-info">
              <span class="info-label"> Merchant Account Number:</span> {{ data.contactPhone }}
            </li>
            <li v-if="data.contactEmail" class="payment-info"><span class="info-label">Email:</span>
              {{ data.contactEmail }}
            </li>
            <li v-if="data.contactWebsite" class="payment-info">
              <span class="info-label">Website:</span>
              {{ data.contactWebsite }}
            </li>
          </ul>
        </div>
      </div>
      <div v-if="data.orderId" class="QR" style="max-height: 146px;max-width: 146px;">
        <img :src="data.qrImg" alt="QR" style="height: 100%;width: 100%;">
      </div>
    </div>

    <div class="customer-info">
      <div class="name-info-container">
        <div class="customer-name">
          Customer Information
        </div>

        <div v-if="data.createdDate" class="success-item-text">{{ data.createdDate | displayFormattedDate }}</div>
        <div v-if="data.transactionDateTime" class="success-item-text">{{ data.transactionDateTime | displayFormattedDate }}</div>
      </div>

      <div class="divider"></div>

      <ul class="info-container">
        <li class="payment-info">
          <span class="info-label">bKash Account: </span>
          <span>{{ data.customerPhoneNumber }}</span>
        </li>
        <li v-if="data.customerReference" class="payment-info">
          <span class="info-label">Reference: </span>
          <span>{{ data.customerReference }}</span>
        </li>
      </ul>

      <ul v-for="(value,key) in data.customerInformation" class="reference_container" :key="key">
        <li v-if="!!value && key!=='Payment Reference'" class="payment-info">
          <span class="info-label">{{ key }}:</span>
          <span>&nbsp;{{ value }}</span>
        </li>
      </ul>
    </div>

    <div class="payment-info-container">
      <div class="payment-name">
        Payment Information
      </div>

      <div class="divider"></div>

      <ul class="info-container customer-info">
        <li class="payment-info"><span class="info-label">Status: </span><span
            class="info-value">{{ data.paymentStatus }}</span>
        </li>
        <li class="payment-info"><span class="info-label">Transaction ID: </span><span
            class="trx-text">{{ data.trxId }}</span></li>
        <li class="payment-info"><span class="info-label">Invoice ID: </span><span
            class="trx-text">{{ data.invoiceNumber }}</span></li>
      </ul>
    </div>

    <div class="horizontal-tab">
      <div class="item-container">
        <div class="row margin-style info-container">
          <h3 class="header-text" style="width: 40%">
            Item Name
          </h3>
          <div class="header-container">
            <h3 class="header-text" style="width: 20%">Price</h3>
            <h3 class="header-text" style="width: 20%">Fee</h3>
            <h3 class="header-text" style="width: 20%">Total</h3>
          </div>
        </div>
        <div class="divider"></div>

        <div class="row margin-style element-container">
          <p class="item-element word-break" style="width: 40%">
            {{
              data.productName ? data.productName : (data.productDescription ? data.productDescription : "-")
            }}
          </p>
          <div class="header-container">
            <p class="item-element word-break" style="width: 20%">{{ data.price | displayCurrency }}</p>
            <p class="item-element word-break" style="width: 20%">{{ data.fee | displayCurrency }}</p>
            <p class="item-element word-break" style="width: 20%">{{ data.amount | displayCurrency }}</p>
          </div>
        </div>
        <div class="divider"></div>
        <div class="total-amount-container">
          <p>Total</p>
          <p>{{ data.amount | displayCurrency }}</p>
        </div>
      </div>
    </div>

    <div class="vertical-tab-header">
      <div class="item-container" style="align-items: baseline">
        <h3 class="header-text margin-style info-container">Item Name</h3>
        <p class="item-element margin-style word-break" style="margin-bottom: 0;text-align: right">
          {{
            data.productName ? data.productName : (data.productDescription ? data.productDescription : "-")
          }}
        </p>
      </div>
      <div class="divider"></div>
    </div>
    
    <div class="vertical-tab">
      <div class="item-container">
        <div class="row margin-style info-container">
          <h3 class="header-text">Price</h3>
          <h3 class="header-text">Fee</h3>
          <h3 class="header-text">Total</h3>
        </div>

        <div class="row margin-style element-container">
          <p class="item-element">{{ data.price | displayCurrency }}</p>
          <p class="item-element">{{ data.fee | displayCurrency }}</p>
          <p class="item-element">{{ data.amount | displayCurrency }}</p>
        </div>
      </div>

      <div class="divider"></div>
      <div class="total-amount-container">
        <p>Total</p>
        <p>{{ data.amount | displayCurrency }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "Invoice",
  props: ["data"],

  mounted() {},

  filters: {
    displayCurrency(value) {
      if (!value) return '-'
      return `${parseFloat(value).toFixed(2)}`
    },
    displayFormattedDate(date) {
      const allowedDateFormats = ['YYYY-MM-DD HH:mm:ss:SSSZ', 'DD/MM/YY hh:mm A']
      if (!date) return;

      return moment(date, allowedDateFormats)
          .format('MMMM D, YYYY');
    }
  },

  methods: {}
}


</script>

<style scoped>
.word-break {
  word-break: break-all;
}
.invoice-container {
  background: #fff;
  max-width: 900px;
  margin-top: 45px;
  padding: 100px 7% 95px;
}

.margin-style {
  display: flex;
  justify-content: space-between;
  margin-left: 1px;
  margin-right: 1px;
}

.merchant-logo {
  width: auto;
  height: 60px;
}

.trx-text {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: center;
  color: #464646;
}

.customer-name,
.merchant-name,
.payment-name {
  color: #363636;
  font-weight: 800;
  font-size: 20px;
  margin-bottom: 10px;
  margin-left: 6px;
}

ul {
  padding: 0;
  list-style: none;
  word-break: break-all;
}

ul li {
  margin: 5px 0;
}

.horizontal-tab {
  display: block;
}

.vertical-tab, .vertical-tab-header {
  display: none;
}

.success-item-text {
  align-self: center;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: #000000de;
  text-align: right;
  margin-bottom: 10px;
}

.header-text {
  font-size: 14px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #363636;
}

table {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  border-collapse: collapse;
}

table tr {
  border-top: 2px solid #eeeeee;
  border-bottom: 2px solid #eeeeee;
}

table tr td:first-child {
  text-align: left !important;
}

table tr td:last-child {
  text-align: right !important;
}

table tr td:not(:first-child) {
  white-space: nowrap;
}

table tr:last-child {
  font-size: 20px;
  font-weight: 500;
}

table td {
  padding: 10px;
}

.payment-info {
  font-size: 14px;
  word-break: break-word;
}

.info-label {
  font-weight: 500;
}

.divider {
  border: 2px solid rgb(226, 19, 110);
}

.info-container {
  margin-left: 8px;
  margin-top: 12px;
}

.customer-info {
  margin-bottom: 25px;
}

.reference_container {
  margin-left: 8px;
}

.info-value {
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: center;
  color: #01846c;
  font-size: 16px;
  font-weight: 500;
}

.header-container {
  width: 45%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.item-container > .divider {
  margin-top: 10px;
  margin-bottom: 20px;
}

.item-element {
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #363636;
}

.element-container {
  margin-left: 10px;
}

.payment-info-container {
  margin-bottom: 30px;
}

.total-amount-container {
  font-size: 18px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #363636;
  display: flex;
  justify-content: space-between;
  margin-left: 8px;
}

.vertical-tab > .item-container > .margin-style, .vertical-tab-header > .item-container > .margin-style {
  display: flex;
  flex-direction: column !important;
  width: 50%;
}

.vertical-tab > .item-container > .info-container {
  margin-top: 0;
}

.vertical-tab > .item-container, .vertical-tab-header > .item-container {
  display: flex;
}

.vertical-tab > .item-container > .element-container {
  margin-left: 0;
}

.vertical-tab > .item-container > .element-container > .item-element {
  width: 100%;
  text-align: right;
}

.vertical-tab > .item-container > .info-container > .divider {
  margin-left: -8px;
  width: 120%;
}

.vertical-tab .divider, .vertical-tab-header .divider {
  margin-top: 10px;
  margin-bottom: 20px;
}

.vertical-tab > .item-container h3 {
  margin-bottom: 16px;
}

.name-info-container {
  display: flex;
  justify-content: space-between;
}

.merchant-info {
  margin-bottom: 25px;
}

@media only screen and (max-width: 1024px) {
  .invoice-container {
    width: 700px;
  }
}

@media only screen and (max-width: 730px) {
  .invoice-container {
    width: 100%;
    padding-left: 2%;
    padding-right: 2%;
    margin-top: 0;
  }

  .merchant-logo {
    width: auto;
    height: 40px;
  }
}

@media only screen and (max-width: 425px) {
  .header-text {
    font-size: 17px;
  }

  .customer-info, .merchant-info {
    width: 100%;
  }

  .merchant-info {
    margin-bottom: 20px;
  }

  .horizontal-tab {
    display: none;
  }

  .vertical-tab, .vertical-tab-header {
    display: block;
  }
}
</style>